<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="600"
    style="z-index: 6"
  >
    <v-form ref="addPrinterForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Printer</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="flex-wrap justify-center">
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    label="Naziv printera"
                    autofocus
                    v-model="name"
                    class="pt-2"
                    :rules="[rules.req]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    label="Printer adresa servera"
                    v-model="ipAddress"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    label="Ime printera na mreži"
                    v-model="printerName"
                    :rules="[rules.req]"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Dodaj printer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
// import state from '@/state'
// import clone from '@/plugins/utils'

export default {
  components: { },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    location: undefined,
    submitting: false,
    name: undefined,
    ipAddress: undefined,
    printerName: undefined,
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    listeners: []
  }),
  mounted () {
  },
  methods: {
    open (locationId) {
      this.visible = true
      this.submitting = false
      this.locationId = locationId
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.name = undefined
      this.ipAddress = undefined
      this.printerName = undefined
      this.visible = false
      this.location = undefined
    },
    async submit () {
      const printerId = uuidv4()
      const docRef = df.doc(`location_printers/${this.locationId}/printers/${printerId}`)

      try {
        await docRef.set(
          {
            id: printerId,
            name: this.name,
            ip_address: this.ipAddress,
            printer_name: this.printerName
          }
        )
        console.log('Printer successfully saved.')
      } catch (err) {
        console.error('Error saving printer: ', err)
      }

      this.resetInputs()
    }
  }

}
</script>
