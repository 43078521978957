<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="600"
    style="z-index: 6"
  >
    <v-form ref="editOrgUnitForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Ustrojstvena jedinica</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text v-if="orgUnit">
          <v-row class="flex-wrap justify-center">
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    outlined
                    label="Naziv ustrojstvene jedinice"
                    autofocus
                    v-model="orgUnit.name"
                    class="pt-2"
                    :rules="[rules.req]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7" class="pb-0">
                  <v-select
                    outlined
                    dense
                    label="Odaberite printer"
                    :items="printers"
                    item-text="name"
                    item-value="id"
                    v-model="orgUnit.printer"
                  ></v-select>
                </v-col>
                <v-col cols="5">
                  <v-btn
                    x-small
                    class="mt-2"
                    style="z-index: 4"
                    bottom
                    right
                    plain
                    text
                    @click="openAddPrinter"
                  >
                    <v-icon>mdi-plus</v-icon>
                    <span>Dodaj printer</span>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="7" class="pb-0">
                  <v-select
                    multiple
                    outlined
                    dense
                    label="Odaberite kategorije artikala"
                    :items="categories"
                    return-object
                    item-text="name"
                    v-model="selectedCategories"
                  ></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Spremi izmjene
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <add-printer ref="addPrinter"></add-printer>
  </v-dialog>
</template>
<script>
import { functions, df } from '@/plugins/firebase'
import rules from '@/plugins/rules'
import AddPrinter from '@/modules/point-of-sale/components/printer-configuration/AddPrinter'
import state from '@/state'
import { clone } from '@/plugins/utils'

export default {
  components: { AddPrinter },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    location: undefined,
    submitting: false,
    orgUnit: undefined,
    printers: [],
    rules: {
      req: rules.req(),
      eq: rules.eq,
      arrReq: rules.arrReq()

    },
    selectedCategories: [],
    categories: [],
    sortedCategories: [],
    listeners: []
  }),
  mounted () {
  },
  methods: {
    openAddPrinter () {
      this.$refs.addPrinter.open(state.getPointOfSale().id)
    },
    open (locationId, orgUnit) {
      const query = df
        .collection(`location_printers/${state.getPointOfSale().id}/printers`)
      const listener = query
        .onSnapshot((doc) => {
          doc.docs.forEach((rec) => {
            this.printers.push(rec.data())
          })
        }
        )
      this.listeners.push(listener)

      this.listeners.push(
        df
          .doc(`company_categories/${state.getCurrentCompany().id}`)
          .onSnapshot((doc) => {
            if (
              doc &&
              doc.data() &&
              doc.data().categories &&
              Object.keys(doc.data().categories).length > 0
            ) {
              this.categories = Object.keys(doc.data().categories).map(
                (key) => {
                  return doc.data().categories[key]
                }
              )
            }
            this.sortedCategories = clone(this.categories.sort((a, b) => a.name.localeCompare(b.name)))
          })
      )

      if (orgUnit.printer && orgUnit.printer !== '') {
        this.printers.forEach(printer => {
          if (printer.id === orgUnit.printer) {
            orgUnit.printer = printer
          }
        })
      }

      this.orgUnit = orgUnit
      this.visible = true
      this.submitting = false
      this.locationId = locationId
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.orgUnit = undefined
      this.visible = false
      this.location = undefined
      this.selectedCategories = []
    },
    async submit () {
      if (!this.orgUnit) {
        return
      }
      const tblId = this.orgUnit.id
      const docRef = df.doc(`location_units/${this.locationId}/units/${tblId}`)

      const categories = this.selectedCategories.map((category) => {
        return {
          id: category.id,
          name: category.name,
          picture: category.picture
        }
      })

      if (categories && categories.length > 0) {
        const payload = {
          action: {
            operation: 'set',
            entity: 'organizational_unit',
            params: {
              organizational_unit_id: tblId,
              company_id: state.getCurrentCompany().id,
              location_id: state.getPointOfSale().id,
              categories: categories
            }
          }
        }

        try {
          var companyitemApi = functions.httpsCallable('companyitemapi')

          companyitemApi({
            action: {
              operation: payload.action.operation,
              entity: payload.action.entity,
              resource_id: payload.action.resource_id,
              params: payload.action.params
            }
          })
            .then((result) => {
              if (result.data.code === 200) {

              } else {
                this.showMsgBox({
                  text: result.data.message ?? 'Dogodila se pogreška.',
                  actions: ['cancel'],
                  cancelBtnText: 'OK',
                  color: 'error'
                })
              }
            })
        } catch (err) {
          this.showMsgBox({
            text: err && err !== '' ? err : 'An error has occurred',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
        }
      }

      try {
        await docRef.set(
          {
            id: tblId,
            name: this.orgUnit.name,
            printer: this.orgUnit.printer
          }
        )
        console.log('Table successfully saved.')
      } catch (err) {
        console.error('Error saving table: ', err)
      }

      this.resetInputs()
    }
  }

}
</script>
