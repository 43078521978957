<template>
  <div style="width: 100%" class="fill-height">
    <v-btn
      style="z-index: 4"
      rounded
      fixed
      bottom
      right
      color="primary"
      @click="openAddOrgUnit"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-icon>mdi-plus</v-icon>
      <span>{{ $t(`$vuetify.company.addOrgUnitBtn`) }}</span>
    </v-btn>
    <v-container>
      <v-row style="margin-top: 40px" class="d-flex justify-center">
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card v-for="(orgUnit, i) in orgUnits" :key="i" hover class="pb-2">
            <v-row class="ma-0 pa-0">
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="2"
                :class="[densityPadding]"
              >
                <div class="caption grey--text text-center">
                  Ime ustrojstvene jedinice
                </div>
                <div class="text-center">{{ orgUnit.name }}</div>
              </v-col>
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="2"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1', 'flex-shrink-0']"
              >
                <div class="caption grey--text text-center">Printer</div>
                <div class="text-capitalize text-center">
                  {{ orgUnit.printerName }}
                </div>
              </v-col>
              <v-col
                cols="4"
                sm="4"
                md="2"
                lg="2"
                xl="2"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1', 'flex-shrink-0']"
              >
                <div class="d-flex justify-end align-center pl-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="openEditOrgUnit(orgUnit)"
                        @keypress.enter="openEditOrgUnit(orgUnit)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Uredi ustrojstvenu jedinicu</span>
                  </v-tooltip>
                </div></v-col
              >
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
      <add-organizational-unit ref="addOrgUnit"></add-organizational-unit>
      <edit-organizational-unit ref="editOrgUnit"></edit-organizational-unit>
    </v-container>
  </div>
</template>
<script>
import state from '@/state'
import AddOrganizationalUnit from '@/modules/point-of-sale/components/organizational-unit-configuration/AddOrganizationalUnit'
import EditOrganizationalUnit from '@/modules/point-of-sale/components/organizational-unit-configuration/EditOrganizationalUnit'
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import InfiniteLoading from 'vue-infinite-loading'
import applicationSettings from '@/mixins/applicationSettings'

export default {
  name: 'OrganizationUnitConfiguration',
  mixins: [applicationSettings],
  components: { AddOrganizationalUnit, InfiniteLoading, EditOrganizationalUnit },
  data: () => ({
    location: undefined,
    orgUnits: [],
    orgUnitsSorted: [],
    printers: [],
    listeners: []
  }),
  computed: {

  },
  watch: {

  },
  mounted () {
    const query = df
      .collection(`location_printers/${state.getPointOfSale().id}/printers`)
    const listener = query
      .onSnapshot((doc) => {
        doc.docs.forEach((rec) => {
          this.printers.push(rec.data())
        })
      }
      )
    this.listeners.push(listener)
  },
  beforeDestroy () {

  },
  methods: {
    openAddOrgUnit () {
      this.$refs.addOrgUnit.open(state.getPointOfSale().id)
    },
    openEditOrgUnit (orgUnit) {
      this.$refs.editOrgUnit.open(state.getPointOfSale().id, orgUnit)
    },
    modifyOrgUnits (changedOrgUnit) {
      if (changedOrgUnit.printer && changedOrgUnit.printer !== '') {
        this.printers.forEach(printer => {
          if (printer.id === changedOrgUnit.printer) {
            changedOrgUnit.printerName = printer.name
          }
        })
      }
      let found = false
      this.orgUnits.forEach((orgUnit, key) => {
        if (orgUnit.id === changedOrgUnit.id) {
          found = true
          this.orgUnits[key] = changedOrgUnit
        }
      })

      if (!found) {
        this.orgUnits.push(changedOrgUnit)
      }

      this.orgUnitsSorted = clone(this.orgUnits.sort((a, b) => a.name.localeCompare(b.name)))
    },
    infiniteHandler ($state) {
      if (state.getPointOfSale().id !== '') {
        const query = df.collection(`location_units/${state.getPointOfSale().id}/units`)
        const listener = query
          .onSnapshot((doc) => {
            doc.docs.forEach((rec) => {
              this.modifyOrgUnits(rec.data())
            })
            $state.complete()
          })
        this.listeners.push(listener)
      } else {
        $state.complete()
      }
    }
  }
}
</script>
<style scoped>
</style>
